import axios from 'axios';
import { APIURL } from '../../constant/APIURL';

export interface Cart {
    cartId?: number;
    userId?: number;
    menuId?: number;
    quantity?: number;
    price?: number;
    bunOption?: string;
    serveOption?: string;
    addons?: string[];
    note?: string;
}

export const createCart = async (cart: Cart) => {
   try {
        const response = await axios.post(`${APIURL}/cart`, cart,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error creating cart:", error);
    }
}

export const deleteCart = async (cartId: number) => {
    try {
        const response = await axios.delete(`${APIURL}/cart/${cartId}`,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },

        });
        return response.data;
    } catch (error) {
        console.error("Error deleting cart:", error);
    }
}