import { Avatar } from "../Assets";
import logoImge from '../../img/logo2test.png';
import { Link } from "react-router-dom";

import DropDown from "./DropDown";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import LoginAction from "./LoginAction";
import MobileNav from "./mobile-nav";
import Navigations from "./Navigations";
import { RiArrowDropDownLine } from "react-icons/ri";
import { motion } from "framer-motion";
import { useState } from "react";
import { useStateValue } from "../../context/StateProvider";

const Header = () => {
  const [{ user }] = useStateValue();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);

  return (
    <header className="w-screen fixed z-50 bg-black/90 backdrop-blur-md shadow-lg md:p-4 lg:p-6 lg:px-16">
    {/* Tablet and Desktop */}
    <div className="hidden md:flex w-full justify-between items-center">
      <Link to={"/"}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="flex items-center gap-2 cursor-pointer"
        >
          <img
            src={logoImge}
            alt="Logo"
            className="w-10 h-10 object-cover"
          />
          <h1 className="text-xl font-bold text-white">Flavour Fusion</h1>
        </motion.div>
      </Link>
  
      {/* Navigation */}
      <Navigations />
  
      {/* User Profile */}
      {user ? (
        <div className="group flex items-center gap-4 px-4 py-2 rounded-lg bg-gray-900 shadow-md">
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="flex items-center justify-center cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              src={user.photoURL || Avatar}
              className="w-10 h-10 rounded-full shadow-lg"
              alt="profile"
            />
            <p className="text-white font-semibold ml-2">{user.displayName}</p>
            <RiArrowDropDownLine className="text-2xl text-white" />
          </motion.div>
          {isOpen && <DropDown user={user} />}
        </div>
      ) : (
        <LoginAction text={"Login"} />
      )}
    </div>
  
    {/* Mobile */}
    <motion.div
      className="flex md:hidden w-full p-4 items-center justify-between"
      initial={{ opacity: 0, x: 200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 200 }}
    >
      {isOpenMobileNav ? (
        <MobileNav isOpen={isOpenMobileNav} setIsOpen={setIsOpenMobileNav} />
      ) : (
        <div className="flex items-center justify-between w-full">
          <motion.div
            whileTap={{ scale: 0.9 }}
            className="flex items-center justify-center cursor-pointer"
            onClick={() => setIsOpenMobileNav(!isOpenMobileNav)}
          >
            <HiOutlineMenuAlt2 className="text-white text-4xl" />
          </motion.div>
          <Link to={"/"}>
            <motion.div
              whileHover={{ scale: 1.1 }}
              className="flex items-center gap-2 cursor-pointer"
            >
              <img src={logoImge} alt="Logo" className="w-8 h-8 object-cover" />
            </motion.div>
          </Link>
          {user ? (
            <div className="flex items-center gap-3 px-3 py-1 rounded-lg relative">
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="flex items-center justify-center cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
              >
                <img
                  src={user?.photoURL ? user.photoURL : Avatar}
                  className="w-10 h-10 rounded-full shadow-lg"
                  alt="user-profile"
                />
                <p className="text-white font-semibold ml-2">{user.displayName}</p>
                <RiArrowDropDownLine className="text-2xl text-white" />
                {isOpen && <DropDown user={user} />}
              </motion.div>
            </div>
          ) : (
            <LoginAction mobile />
          )}
        </div>
      )}
    </motion.div>
  </header>
  
  );
};

export default Header;
