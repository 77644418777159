import React, { useState, useEffect } from "react";
import { FoodItem } from "../../../types";
import { motion } from "framer-motion";
import { LoadingSkeleton } from "../Assets/LoadingSkeleton";
import Modal from "react-modal";
import { createCart } from "../../service/cart/cartService";
import { getUserIdFromToken } from "../../utils/auth";
import { getStoreStatus } from "../../service/Store/storeService";
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    padding: "20px",
    borderRadius: "12px",
    backgroundColor: "#1a1a1a",  // Set to a dark background color
    color: "#ffffff",  // Ensure text color is white
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.8)",  // Darken the shadow for a black theme
  },
};


export const SingleFoodItem = ({
  item,
  col,
  admin,
}: {
  item: FoodItem;
  col?: boolean;
  admin?: boolean;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addons, setAddons] = useState<{ [key: string]: { name: string; price: number; quantity: number } }>({});
  const [notes, setNotes] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedBunOption, setSelectedBunOption] = useState('');
  const [selectedServeType, setSelectedServeType] = useState<string | null>(null);
  const [selectedBurger, setSelectedBurger] = useState('');
  const [selectedDrink, setSelectedDrink] = useState('');

  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const cookies = new Cookies();

  const handleRadioClick = (e: any) => {
    const selectedValue = e.target.value;
    setSelectedBunOption(prevState => prevState === selectedValue ? '' : selectedValue);
  };

  const handleDeselect = () => {
    setSelectedBunOption('');
  }

  useEffect(() => {
    const { cartItems, totalAmount } = getCartFromCookies();
    setCart(cartItems);
    setTotalAmount(totalAmount);
  }, []);

  useEffect(() => {
    setAddons(
      item?.addons?.reduce((acc: any, addon: any) => {
        acc[addon.id] = { name: addon.name, price: addon.price, quantity: 0 };
        return acc;
      }, {})
    );
  }, [item.addons]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentStep(1);
  };

  const saveCartToCookies = (response: any) => {
    try {
      const { totalAmount, data } = response;
      let existingCartData = cookies.get('cart');
      if (existingCartData) {
        existingCartData = existingCartData;
      } else {
        existingCartData = { data: [], totalAmount: 0 };
      }

      if (Array.isArray(data)) {
        data.forEach((item: any) => {
          existingCartData.data.push({
            cartId: item.cartId,
            userId: item.userId,
            userName: item.userName,
            menuId: item.menuId,
            menuName: item.menuName,
            price: item.price,
            quantity: item.quantity,
            bunOption: item.bunOption,
            serveType: item.serveType,
            note: item.note,
            imageUrl: item.imageUrl,
            addons: item.addons,
          });
        });
      } else {
        console.error('Error: data is not an array or is undefined');
        return;
      }

      existingCartData.totalAmount = totalAmount;

      const cartString = existingCartData;
      cookies.set('cart', cartString, { path: '/' });

      setCart(existingCartData.data);
      setTotalAmount(totalAmount);

      toast.success("Item added to cart!");
    } catch (error) {
      console.error('Error saving cart to cookies:', error);
    }
  };

  const getCartFromCookies = () => {
    const existingCart = cookies.get('cart');
    let cartItems: any = [];
    let totalAmount = 0;

    try {
      if (existingCart) {
        const parsedCartData = existingCart;
        cartItems = parsedCartData?.data?.map((item: any) => {
          return { ...item, transformed: true };
        });
        totalAmount = parsedCartData.totalAmount;
      }
    } catch (error) {
      throw new Error('Something went wrong');
    }

    return { cartItems, totalAmount };
  };

  const handleAddToCart = async () => {
    const userId = getUserIdFromToken();
    const selectedAddons = Object.keys(addons)
      .filter(key => addons[key].quantity > 0)
      .map(key => ({
        id: key,
        name: addons[key].name,
        price: addons[key].price,
        quantity: addons[key].quantity,
      }));
    const cart: any = {
      userId,
      menuId: item.id,
      menuName: item.name,
      imageUrl: item.imageUrl,
      quantity: 1,
      price: calculateTotalPrice(),
      bunOption: selectedBunOption,
      serveType: selectedServeType,
      burgerOption: selectedBurger,
      drinkOption: selectedDrink,
      addons: selectedAddons,
      note: notes,
    };

    if (!userId) {
      saveCartToCookies({ totalAmount: calculateTotalPrice(), data: [cart] });
      closeModal();
      return;
    }

    try {
      const response = await createCart(cart);
      toast.success("Item added to cart!");
      if (!userId) {
        saveCartToCookies(response);  // Save response to cookies
      }
    } catch (error) {
      console.error(error);
      toast.error("Error adding item to cart, please try again later.");
    }
    closeModal();
  };

  const handleIncrement = (addonId: string) => {
    const updatedAddons = { ...addons };
    updatedAddons[addonId].quantity += 1;
    setAddons(updatedAddons);
  };

  const handleDecrement = (addonId: string) => {
    const updatedAddons = { ...addons };
    if (updatedAddons[addonId].quantity > 0) {
      updatedAddons[addonId].quantity -= 1;
      setAddons(updatedAddons);
    }
  };

  const calculateTotalPrice = () => {
    const addonsTotal = Object.keys(addons).reduce((acc, key) => {
      const addon = addons[key];
      return acc + addon.price * addon.quantity;
    }, 0);
    let totalPrice = parseFloat((Number(item.price) + addonsTotal).toFixed(2));
    if (selectedServeType === "Combo") {
      totalPrice += 4;
    }
    return totalPrice > 0 ? totalPrice : 0;
  };

  const nextStep = () => {
    if (item.itemType === "Combo") {
      setCurrentStep(4); // Skip to the last step for Combo items
    } else {
      if (currentStep === 1 && !selectedServeType) {
        toast.error("Please select a Serve option.");
        return;
      }
      setCurrentStep((prev) => Math.min(prev + 1, 4));
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const renderComboContent = () => (
    <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md">
      <h3 className="text-lg font-semibold text-gray-700">Combo Options</h3>
      <h4 className="text-md font-semibold text-gray-700 mt-4">Select your burger</h4>
      <ul className="list-disc pl-5 mt-2">
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="chickenBurger"
            name="burger"
            value="Chicken Burger"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedBurger === "Chicken Burger"}
            onChange={(e) => setSelectedBurger(e.target.value)}
          />
          <label htmlFor="chickenBurger" className="text-gray-700 cursor-pointer">Chicken Burger</label>
        </li>
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="satayChicken"
            name="burger"
            value="Satay Chicken"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedBurger === "Satay Chicken"}
            onChange={(e) => setSelectedBurger(e.target.value)}
          />
          <label htmlFor="satayChicken" className="text-gray-700 cursor-pointer">Satay Chicken</label>
        </li>
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="chipotleChicken"
            name="burger"
            value="Chipotle Chicken"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedBurger === "Chipotle Chicken"}
            onChange={(e) => setSelectedBurger(e.target.value)}
          />
          <label htmlFor="chipotleChicken" className="text-gray-700 cursor-pointer">Chipotle Chicken</label>
        </li>
      </ul>

      <h4 className="text-md font-semibold text-gray-700 mt-4">Select your drink</h4>
      <ul className="list-disc pl-5 mt-2">
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="cocaColaOriginal"
            name="drink"
            value="Coca-Cola Original 330ml"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedDrink === "Coca-Cola Original 330ml"}
            onChange={(e) => setSelectedDrink(e.target.value)}
          />
          <label htmlFor="cocaColaOriginal" className="text-gray-700 cursor-pointer">Coca-Cola Original 330ml</label>
        </li>
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="cocaColaZero"
            name="drink"
            value="Coca-Cola Zero Sugar 330ml"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedDrink === "Coca-Cola Zero Sugar 330ml"}
            onChange={(e) => setSelectedDrink(e.target.value)}
          />
          <label htmlFor="cocaColaZero" className="text-gray-700 cursor-pointer">Coca-Cola Zero Sugar 330ml</label>
        </li>
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="sprite"
            name="drink"
            value="Sprite 330ml"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedDrink === "Sprite 330ml"}
            onChange={(e) => setSelectedDrink(e.target.value)}
          />
          <label htmlFor="sprite" className="text-gray-700 cursor-pointer">Sprite 330ml</label>
        </li>
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="lp"
            name="drink"
            value="L&P 330ml"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedDrink === "L&P 330ml"}
            onChange={(e) => setSelectedDrink(e.target.value)}
          />
          <label htmlFor="lp" className="text-gray-700 cursor-pointer">L&P 330ml</label>
        </li>
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="fanta"
            name="drink"
            value="Fanta 330ml"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedDrink === "Fanta 330ml"}
            onChange={(e) => setSelectedDrink(e.target.value)}
          />
          <label htmlFor="fanta" className="text-gray-700 cursor-pointer">Fanta 330ml</label>
        </li>
        <li className="flex items-center space-x-3">
          <input
            type="radio"
            id="lift"
            name="drink"
            value="Lift 330ml"
            className="form-radio h-5 w-5 text-blue-600"
            checked={selectedDrink === "Lift 330ml"}
            onChange={(e) => setSelectedDrink(e.target.value)}
          />
          <label htmlFor="lift" className="text-gray-700 cursor-pointer">Lift 330ml</label>
        </li>
      </ul>
      <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700">Notes</h3>
        <textarea
          className="w-full h-24 p-2 mt-2 border border-gray-300 rounded"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Any additional instructions..."
        />
      </div>
    </div>
  );

  const renderStepContent = () => {
    if (item.itemType === "Combo") {
      return renderComboContent();
    }

    switch (currentStep) {
      case 1:
        return (
          <div className="relative p-8 max-w-lg mx-auto bg-gradient-to-r from-gray-800 to-gray-900 rounded-3xl shadow-lg transform transition-all hover:shadow-xl hover:-translate-y-1 border-l-8 border-blue-500">
            <div className="absolute inset-x-0 top-0 flex justify-center">
              <div className="w-16 h-1 bg-blue-500 rounded-full"></div>
            </div>
            <h3 className="text-2xl font-extrabold text-white mb-4">Choose Serve Type</h3>
            <p className="text-sm text-gray-400 mb-6">Select how you would like your meal served.</p>
            <div className="space-y-6">
              <div className="flex items-center space-x-4">
                <input
                  type="radio"
                  id="itself"
                  name="serveType"
                  value="Itself"
                  className="form-radio h-6 w-6 text-blue-600 transition-colors duration-200 focus:ring focus:ring-blue-500"
                  checked={selectedServeType === 'Itself'}
                  onChange={(e) => setSelectedServeType(e.target.value)}
                />
                <label htmlFor="itself" className="text-lg text-gray-200 cursor-pointer">
                  Itself
                </label>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="radio"
                  id="combo"
                  name="serveType"
                  value="Combo"
                  className="form-radio h-6 w-6 text-blue-600 transition-colors duration-200 focus:ring focus:ring-blue-500"
                  checked={selectedServeType === 'Combo'}
                  onChange={(e) => setSelectedServeType(e.target.value)}
                />
                <label htmlFor="combo" className="text-lg text-gray-200 cursor-pointer">
                  Combo
                </label>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="relative p-8 max-w-lg mx-auto bg-gradient-to-r from-gray-800 to-gray-900 rounded-3xl shadow-lg transform transition-all hover:shadow-xl hover:-translate-y-1 border-l-8 border-green-500">
            <div className="absolute inset-x-0 top-0 flex justify-center">
              <div className="w-16 h-1 bg-green-500 rounded-full"></div>
            </div>
            <h3 className="text-2xl font-extrabold text-white mb-4">Select Bun Option</h3>
            <p className="text-sm text-gray-400 mb-6">Choose your preferred type of bun.</p>
            <div className="space-y-6">
              <div className="flex items-center space-x-4">
                <input
                  type="radio"
                  id="bunless"
                  name="bun"
                  value="Bunless"
                  className="form-radio h-6 w-6 text-green-600 transition-colors duration-200 focus:ring focus:ring-green-500"
                  checked={selectedBunOption === 'Bunless'}
                  onChange={handleRadioClick}
                />
                <label htmlFor="bunless" className="text-lg text-gray-200 cursor-pointer">
                  Bunless
                </label>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="radio"
                  id="glutenFree"
                  name="bun"
                  value="Gluten Free"
                  className="form-radio h-6 w-6 text-green-600 transition-colors duration-200 focus:ring focus:ring-green-500"
                  checked={selectedBunOption === 'Gluten Free'}
                  onChange={handleRadioClick}
                />
                <label htmlFor="glutenFree" className="text-lg text-gray-200 cursor-pointer">
                  Gluten Free
                </label>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="radio"
                  id="vegan"
                  name="bun"
                  value="Vegan"
                  className="form-radio h-6 w-6 text-green-600 transition-colors duration-200 focus:ring focus:ring-green-500"
                  checked={selectedBunOption === 'Vegan'}
                  onChange={handleRadioClick}
                />
                <label htmlFor="vegan" className="text-lg text-gray-200 cursor-pointer">
                  Vegan
                </label>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="relative p-8 max-w-lg mx-auto bg-gradient-to-r from-gray-800 to-gray-900 rounded-3xl shadow-lg transform transition-all hover:shadow-xl hover:-translate-y-1 border-l-8 border-yellow-500">
            <div className="absolute inset-x-0 top-0 flex justify-center">
              <div className="w-16 h-1 bg-yellow-500 rounded-full"></div>
            </div>
            <h3 className="text-2xl font-extrabold text-white mb-4">Add Your Extras</h3>
            <p className="text-sm text-gray-400 mb-6">Select any additional toppings or sides.</p>
            <div className="mt-6 max-h-60 overflow-y-auto custom-scrollbar">
              <ul className="space-y-4">
                {Object.keys(addons).map((addonId) => (
                  <li key={addonId} className="flex items-center justify-between">
                    <span className="text-lg text-gray-200">{addons[addonId].name}</span>
                    <span className="text-gray-400">${addons[addonId]?.price?.toFixed(2)}</span>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => handleDecrement(addonId)}
                        className="px-2 py-1 bg-yellow-500 text-white rounded-full hover:bg-yellow-600"
                      >
                        -
                      </button>
                      <span className="text-lg text-gray-200">{addons[addonId].quantity}</span>
                      <button
                        onClick={() => handleIncrement(addonId)}
                        className="px-2 py-1 bg-yellow-500 text-white rounded-full hover:bg-yellow-600"
                      >
                        +
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="relative p-8 max-w-lg mx-auto bg-gradient-to-r from-gray-800 to-gray-900 rounded-3xl shadow-lg transform transition-all hover:shadow-xl hover:-translate-y-1 border-l-8 border-red-500">
            <div className="absolute inset-x-0 top-0 flex justify-center">
              <div className="w-16 h-1 bg-red-500 rounded-full"></div>
            </div>
            <h3 className="text-2xl font-extrabold text-white mb-4">Add Special Notes</h3>
            <p className="text-sm text-gray-400 mb-6">Leave any specific instructions for your meal.</p>
            <textarea
              className="w-full h-28 p-4 border border-gray-700 rounded-lg focus:ring focus:ring-red-500 resize-none text-gray-200 bg-gray-800"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Any additional instructions..."
            />
          </div>
        );
      default:
        return null;
    }



  };

  const handleButtonClick = async () => {
    const storeStatus = await getStoreStatus();
    if (storeStatus.data === true) {
      const excludedCategories = ["Sides", "Soft Drinks", "Dessert", "Condiments", "Fried Chicken"];
      if (excludedCategories.includes(item.itemType ?? "")) {
        handleAddToCart();
      } else {
        openModal();
      }
    } else {
      toast.error("Store is closed at the moment, please try again at working hours.");
    }
  };

  return (
    <div className="category-page bg-gradient-to-br from-black via-gray-900 to-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-500 transform hover:-translate-y-2 min-h-[350px]">
    <motion.div
      whileTap={{ scale: 0.98 }}
      className="max-w-sm bg-gray-900 rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <div className="relative w-full h-56 flex justify-center items-center bg-gradient-to-t from-gray-900 via-gray-800 to-gray-700 rounded-t-3xl">
        {!imageLoaded && (
          <div className="loading-skeleton w-48 h-48 bg-gray-700 rounded-full animate-pulse"></div>
        )}
        <motion.img
          whileHover={{ scale: 1.1, rotate: 3, filter: "brightness(1.1)" }}
          whileTap={{ scale: 1 }}
          className="w-48 h-48 object-cover rounded-full transform transition-transform duration-500"
          style={{
            opacity: imageLoaded ? "1" : "0",
            transition: "opacity 0.5s ease-in",
          }}
          alt={item?.description}
          src={item?.imageUrl}
          onLoad={() => setImageLoaded(true)}
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-80 rounded-t-3xl"></div>
      </div>
      <div className="w-full flex flex-col items-center mt-4 text-center p-6">
        <h3 className="text-2xl font-extrabold text-white tracking-wide">{item.name}</h3>
        <p className="mt-2 text-sm text-gray-400 italic">{item.description}</p>
        <div className="flex items-center justify-between mt-4 w-full">
          <p className="text-xl font-semibold text-red-500">${item.price}</p>
          <motion.button
            whileHover={{ scale: 1.1, backgroundColor: "#ff7043" }}
            whileTap={{ scale: 0.95 }}
            onClick={handleButtonClick}
            className="bg-gradient-to-r from-yellow-400 to-red-500 hover:from-yellow-500 hover:to-red-600 text-white font-extrabold py-2 px-6 rounded-full shadow-lg hover:shadow-2xl transition-transform duration-200 transform hover:-translate-y-1"
          >
            Add to Cart
          </motion.button>
        </div>
      </div>
    </motion.div>
  
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel="Customize Item"
      style={modalStyle}
    >
      <h2 className="text-3xl font-extrabold mb-6 text-white">Customize Your {item.name}</h2>
      {renderStepContent()}
      <div className="mt-8 flex justify-between items-center">
        <span className="text-lg font-semibold text-gray-300">Total Price: ${calculateTotalPrice()}</span>
        {currentStep > 1 && (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={prevStep}
            className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded-full"
          >
            Previous
          </motion.button>
        )}
        {currentStep < 4 && item.itemType !== "Combo" ? (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={nextStep}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-8 rounded-full"
          >
            Next
          </motion.button>
        ) : (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleAddToCart}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-8 rounded-full"
          >
            Add to Cart
          </motion.button>
        )}
      </div>
    </Modal>
  </div>
  );
};
