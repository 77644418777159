import { useEffect, useState } from "react";
import Container from "../../Container";
import Filters from "../../Filters";
import { Title } from "..";
import { FoodItem } from "../../../../types";
import axios from "axios";
import { APIURL } from "../../../constant/APIURL";

const Menu = ({ title }: { title?: string }) => {
  const [scrollValue] = useState(0);
  const [filter, setFilter] = useState<string>("all");
  const [data, setData] = useState<FoodItem[]>([]);

  const fetchData = async () => {
    try {
      const result = await axios.get(`${APIURL}/menu-item/menu/${filter}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      setData(result.data.menuList);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);
  
  return (
    <section className="w-full my-5" id="menu">
      <div className="w-full flex items-center justify-center">
        <Title title={title || "Our Hot Dishes"} center />
      </div>
      <Filters  filter={filter} setFilter={setFilter} data={data} />
     {!!data?.length || <Container
        className="bg-containerbg"
        col
        scrollOffset={scrollValue}
      />}
    </section>
  );
};

export default Menu;
