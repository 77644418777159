import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Left = () => {
  return (
    <div className="py-4 flex-1 flex flex-col items-start justify-center gap-5">
      {/* Animated Badge */}
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="flex items-center gap-2 justify-center bg-orange-200 px-5 py-2 rounded-full shadow-md"
      >
        <p className="text-lg text-orange-600 font-semibold">Flavour Fusion</p>
      </motion.div>
      
      {/* Animated Heading */}
      <motion.p
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
        className="text-[2.5rem] lg:text-[4.5rem] font-bold tracking-tight text-white leading-tight"
      >
        HAVING AN INSATIABLE APPETITE!
        {/* Optionally add the highlighted text below */}
        {/* <span className="text-orange-600 text-[3rem] lg:text-[4.8rem]"> New Zealand </span> */}
      </motion.p>

      {/* Animated Description */}
      <motion.p
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2, ease: "easeOut" }}
        className="text-base text-gray-300 md:w-[85%]"
      >
        Treat yourself today with Hot and Delicious Burger in the town. At Flavour Fusion, we create food that makes you feel alive, satisfying your cravings and energizing you with our tempting Fire-grilled Juicy Smashed NZ Beef or Hot Crispy Fried Chicken.
      </motion.p>

      {/* Animated Button */}
      <motion.button
        whileHover={{ scale: 1.15 }}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
        className="bg-gradient-to-r from-orange-400 to-orange-600 w-full md:w-auto px-6 py-3 rounded-lg text-white font-bold shadow-lg transition-transform ease-in-out duration-200"
      >
        <Link to="/menu">Order Now</Link>
      </motion.button>
    </div>
  );
};

export default Left;
