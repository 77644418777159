import { Link } from "react-router-dom";
import Logo from "../../img/logo2test.png";  
import { motion } from "framer-motion";
import Tiktok from '../../img/tiktok_2504942.png'
import Instagram from '../../img/instagram_4138124.png'
import Facebook from '../../img/facebook_2504903.png'
const Footer = () => {
  return (
    <footer className="p-4 bg-primary sm:p-6 w-full">
      <div className="flex justify-center md:justify-start items-center">
        <motion.div
          whileHover={{ rotate: [0, -10, 10, -10, 0] }}
          className="mb-3 md:mb-0"
        >
          <Link to="/" className="flex gap-8 items-center">
            <motion.img
              whileHover={{ rotate: [0, -10, 10, -10, 0] }}
              src={Logo}
              className="w-10 md:w-36 object-contain"
              alt="Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-headingColor">
              Flavour Fusion
            </span>
          </Link>
        </motion.div>
      </div>
      <hr className="my-2 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="flex flex-col items-center justify-center md:flex-row md:justify-between">
        <span className="text-sm text-gray-500 text-center dark:text-gray-400">
          © 2024 Fusion Flavour. All Rights Reserved.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0 md:text-xl">
          <motion.a
            whileTap={{ scale: 1.1 }}
            target={"_blank"}
            rel="noreferrer"
            href="https://www.tiktok.com/@flavourfusionchch?_t=8n1QLwxeXP6&_r=1"
            className="text-textColor h-10 w-10 bg-primary rounded-full flex items-center justify-center"
          >
            <img src={Tiktok} alt="TikTok" />
          </motion.a>
          <motion.a
            whileTap={{ scale: 1.1 }}
            target={"_blank"}
            rel="noreferrer"
            href="https://www.instagram.com/flavourfusionchch?igsh=NTc4MTIwNjQ2YQ=="
            className="text-textColor h-10 w-10 bg-primary rounded-full flex items-center justify-center"
          >
            {/* <BsInstagram /> */}
            <img src={Instagram} alt="Instagram" />
          </motion.a>
          <motion.a
            whileTap={{ scale: 1.1 }}
            target={"_blank"}
            rel="noreferrer"
            href="https://www.facebook.com/profile.php?id=61559883029659&mibextid=LQQJ4d"
            className="text-textColor h-10 w-10 bg-primary rounded-full flex items-center justify-center"
          >
            <img src={Facebook} alt="Facebook" />
          </motion.a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
