import { useEffect, useState } from "react"
import { PrevNext as PrevNextButtons, Title } from ".."
import Container from "../../Container"

const Fruits = (data:any) => {
  // const fruits = FilterFood("fruits")
  const [scrollValue, setScrollValue] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setScrollValue(prevScrollValue => prevScrollValue + 100); // Adjust the value as needed
    }, 3000); // Adjust the interval time as needed

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <section className="w-full my-5">
      <div className="w-full flex items-center justify-between">
        <Title title="Fresh & Tempting Food" />
        {/* <PrevNextButtons onNext={() => setScrollValue(10000)} onPrev = {() => setScrollValue(-10000)} /> */}
      </div>
      <Container className="bg-containerbg" scrollOffset={scrollValue} data={data} />
    </section>
  )
}

export default Fruits
