import axios from "axios";
import { APIURL } from "../../constant/APIURL";

export const getStoreStatus = async () => {
    try {
        const response = await axios.get(`${APIURL}/store`);
        return response.data;
    } catch (error) {
        console.error("Error getting store status:", error);
    }
}