import { Link, useNavigate } from "react-router-dom";
import { ImageBox } from ".";
import { toast } from "react-toastify";
import { useState } from "react";
import { useStateValue } from "../../context/StateProvider";
import { signIn } from "../../service/Auth/authService";
import { getUserIdFromToken } from "../../utils/auth";

const Login = () => {
  const userId = getUserIdFromToken();
  if (userId) {
    window.location.href = '/';
  }
  const navigate = useNavigate();
  const [{ user }, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await signIn(email, password);
      if (response.statusCode === 200) {
        toast.success("Signin successful");
        localStorage.setItem('accessToken', response.accessToken);
        navigate('/');
      } else if (response.status === 400) {
        toast.error("Signup failed");
      } else if (response.message === 'User Not Verified') {
        toast.error("User Not Verified");
      }
    } catch (error) {
      toast.error("Signup failed");
    }
  };

  return (
    <section className="w-full h-auto bg-gray-900 text-white">
      <div className="container mx-auto py-10 h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-3">
          <ImageBox />
          <div className="w-full md:w-[30rem] bg-gray-800 rounded-lg shadow-lg p-8">
            <form onSubmit={handleFormSubmit}>
              <h2 className="text-2xl font-bold text-center mb-6">Sign in to your account</h2>
              <div className="mb-4">
                <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="email">
                  Email address
                </label>
                <input
                  type="text"
                  id="email"
                  className="shadow appearance-none border border-gray-700 rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="password">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="shadow appearance-none border border-gray-700 rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between">
                <Link
                  to="/forgot-password"
                  className="inline-block align-baseline font-bold text-sm text-blue-400 hover:text-blue-600"
                >
                  Forgot password?
                </Link>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleFormSubmit}
                >
                  Sign in
                </button>
              </div>
              <div className="mt-6 text-center">
                <p className="text-sm text-gray-300">
                  Don't have an account?
                  <Link to={"/register"} className="text-blue-400 hover:text-blue-600 ml-1">
                    Sign Up
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
