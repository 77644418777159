// Filters.tsx
import React from "react";
import { motion } from "framer-motion";
import Button from "./Button";
import { Categories } from "../../utils/categories";
import { FoodCategory, FoodItem } from "../../../types";
import { SingleFoodItem } from "../FoodItem";

const Filters = ({ filter, setFilter, data }: { filter: string, setFilter: any, data: FoodItem[] }) => {
  const filteredData = data.filter((item: FoodItem) => {
    if (filter === "all") return true;
    return item.itemType === filter;
  });
  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 200 }}
        className={`w-full py-10 flex items-center justify-start lg:justify-center h-auto gap-4 md:gap-8 px-2 overflow-x-scroll scrollbar-hidden scroll-smooth`}
      >
        {Categories.map((category: FoodCategory) => (
          <Button key={category.id} category={category} filter={filter} setFilter={setFilter} />
        ))}
      </motion.div>
      {!!filteredData.length && (
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 200 }}
          className="w-full py-10 flex flex-wrap justify-center gap-4 md:gap-8 px-2 overflow-y-scroll scrollbar-hidden scroll-smooth"
        >
          {filteredData.map((item: FoodItem) => (
           
              <SingleFoodItem key={item.id} item={item} />
           
          ))}
        </motion.div>
      )}
    </>
  );
};

export default Filters;
