import { Link } from 'react-router-dom';

const PayementFailed = () => {
    return (
      <div className="flex min-h-screen w-full items-center justify-center px-4 ">
      <div className="mx-auto w-full max-w-md rounded-lg bg-white p-8 shadow-lg dark:bg-gray-900">
      <div className="space-y-4">
      <div className="flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-red-500" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 1a9 9 0 100 18A9 9 0 0010 1zm4.95 12.95a7 7 0 11-9.9-9.9l9.9 9.9z" clipRule="evenodd" />
      </svg>
      </div>
      <h1 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-50">
        Payment Failed
      </h1>
      <p className="text-center text-gray-500 dark:text-gray-400">
        We're sorry, but your payment was unsuccessful. Please contact your bank for further assistance.
      </p>
      <div className="flex justify-center">
        <Link to="/" className="w-full">
        
        <button className="w-full bg-white">Contact Bank</button>
        </Link>
      </div>
      </div>
      </div>
      </div>
    );
};

export default PayementFailed;