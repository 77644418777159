import axios from 'axios';
import { APIURL } from '../../constant/APIURL';
import { toast } from 'react-toastify';

const EmailVerificationPage = () => {

  
    const resendEmail = async () => {
        try {
            const response = await axios.post(`${APIURL}/auth/resend-verfication-email`, {
              email: ''  // Add the email address if needed
            });
      
            if (response.status === 200) {
              toast.success('Email sent successfully');
            }
          } catch (error) {
            toast.error('Something went wrong, please try again later.');
          }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-6">
<div className="max-w-xl w-full p-8 bg-white rounded-lg shadow-md dark:bg-white">
        <div className="space-y-4">
        <h1 className="text-2xl font-bold text-center">Email Verification Pending</h1>
        <p className="text-gray-500 dark:text-gray-400 text-center">
          We've sent a verification email to your inbox. Please check your spam or junk folder if you don't see it.
        </p>
        <button className="w-full bg-blue-500 text-white">Reset Email</button>
      </div>
    </div>
  </div>
  );
};

export default EmailVerificationPage;
