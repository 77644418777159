import axios from "axios";
import { APIURL } from "../../constant/APIURL";

interface SignUpData {
    name: string;
    email: string;
    password: string;
    userRole: string;
    phone: string;
    gender: string;
    dateOfBirth: string;
    isEmailSubscribed: boolean;
}
export const signUp = async (data: SignUpData) => {
    const response = await axios.post(`${APIURL}/auth/user-signup`, data, {
        headers: {
            "Content-Type": "application/json",
            
        },
    });
    return response.data;
}

export const signIn = async (email: string, password: string) => {
    const response = await axios.post(`${APIURL}/auth/user-login`, {
        email,
        password,
    });
    return response.data;
}

export const logoutUser = async () => {
    localStorage.clear();
    window.location.reload();
};
