import { useLayoutEffect, useRef } from "react";
import { FoodItem } from "../../../types";
import Loader from "../Loader";
import { SingleFoodItem } from "../FoodItem";
import { motion } from "framer-motion";
import NotFound from "../NotFound";
import { isAdmin } from "../../utils/functions";
import { useStateValue } from "../../context/StateProvider";

const Container = ({
  scrollOffset,
  col,
  className,
  data,
}: {
  scrollOffset: number;
  col?: boolean;
  className?: string;
  data?: any;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [{ user }] = useStateValue();

  useLayoutEffect(() => {
    if (null !== containerRef.current) {
      containerRef.current.scrollLeft += scrollOffset;
    }
  }, [scrollOffset]);

  return (
    <motion.div
      ref={containerRef}
      initial={{ opacity: 0, x: 200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 200 }}
      className={`${className} w-full my-12 flex items-center justify-center ${col ? "flex-wrap gap-8" : "gap-4 sm:gap-6 overflow-x-scroll scrollbar-hidden"
        } min-h-[200px] px-2 sm:px-4`}
    >
      {data?.data?.length > 0 ? (
        data?.data?.map((item: FoodItem) => (
          <SingleFoodItem
            key={item.id}
            item={item}
            col={col}
            admin={isAdmin(user)}
          />
        ))
      ) : data?.data?.length === 0 ? (
        <NotFound text="No Food Items Available" />
      ) : col ? (
        <NotFound text="Fetching Food Items..." />
      ) : (
        <Loader progress={"Fetching Food Items..."} />
      )}
    </motion.div>

  );
};

export default Container;
