import React from 'react';
import photo1 from '../../img/shop1.jpg';
import photo2 from '../../img/image0.jpeg';
import photo3 from '../../img/shop3.jpg';

const About = () => {
  return (
    <div className="container mx-auto px-4 py-16 bg-gray-900">
      <h1 className="text-5xl font-bold text-center mb-12 text-white leading-tight tracking-wide">
        About Us
      </h1>
      <p className="text-lg text-center mb-12 max-w-3xl mx-auto text-gray-300 leading-relaxed">
        At FlavourFusion, in the heart of Christchurch, we believe in no compromises. This applies not only to our food but also to the customer service we provide. All our ingredients are locally sourced, ensuring both quality and freshness. To cater to your cravings, we frequently offer deals that not only satisfy your taste buds but also provide excellent value for your money.
      </p>

      {/* Image Gallery */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-6 mb-12">
        <div className="relative group overflow-hidden rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105">
          <img
            src={photo2}
            alt="Restaurant Photo 1"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50 transition-opacity duration-300 group-hover:opacity-70"></div>
        </div>
        <div className="relative group overflow-hidden rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105">
          <img
            src={photo1}
            alt="Restaurant Photo 2"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50 transition-opacity duration-300 group-hover:opacity-70"></div>
        </div>
        <div className="relative group overflow-hidden rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105">
          <img
            src={photo3}
            alt="Restaurant Photo 3"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50 transition-opacity duration-300 group-hover:opacity-70"></div>
        </div>
      </div>

      {/* Our Story Section */}
      <div className="text-center max-w-4xl mx-auto">
        <div className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-white leading-snug">
            Our Story
          </h2>
          <p className="text-gray-400 leading-relaxed">
            At FlavourFusion, we take our time to create the perfect flavors. Many of our ingredients are prepared in-house, and our meats are always marinated to perfection. This is what makes our menu so special! Discover the best dishes you didn’t even know you craved: fire-grilled juicy smashed NZ beef or hot crispy fried chicken served on brioche buns that taste like heaven. Since opening in 2020, our restaurant has quickly become a favorite spot for both locals and visitors. We pride ourselves on using fresh, locally sourced ingredients to create dishes that are both flavorful and satisfying.
          </p>
        </div>

        {/* Meet the Team Section */}
        <div>
          <h2 className="text-3xl font-semibold mb-4 text-white leading-snug">
            Meet the Team
          </h2>
          <p className="text-gray-400 leading-relaxed">
            Whether you visit us in Christchurch CBD or engage with us online, our team shares a passion for creating tasty dishes using only the finest ingredients. We promise an experience that will keep you coming back for more! Don’t let hunger take control of you. Beat it with FlavourFusion.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
