import beefIcon from '../img/Icons/Beef.jpg';
import GardenImage from '../img/Icons/Garden.jpg';
import FriedChicken from '../img/Icons/Fried Chicken.jpg'
import SoftDrink from '../img/Icons/Drinks.jpg'
import Chicken from '../img/Icons/Chicken.jpg';
import Sides from '../img/Icons/Sides.jpg';
import Dessert from '../img/Icons/Dessert.jpg';
import Condiments from '../img/Icons/Condiments.jpg';
import Combo from '../img/Icons/combo_2.jpg';

export const Categories = [
    {
        id: 1,
        name: "Garden",
        urlParam: 'Garden',
        icon: <img src={GardenImage} alt="Beef Icon" />,
    },
    {
        id: 2,
        name: "Chicken",
        urlParam: 'Chicken',
        icon: <img src={Chicken} alt="Chicken Icon" />,
    },
    {
        id: 3,
        name: "Beef",
        urlParam: 'Beef',
        icon: <img src={beefIcon} alt="Beef Icon" />,
    },
    {   
        id: 4,
        name: "Fried Chicken",
        urlParam: 'Fried Chicken',
        icon: <img src={FriedChicken} alt="Fried Chicken Icon" />,
    },
    {
        id: 5,
        name: "Sides",
        urlParam: 'Sides',
        icon: <img src={Sides} alt="Fires Icon" />,
    },
    {
        id: 6,
        name: "Soft Drinks",
        urlParam: 'Soft Drinks',
        icon: <img src={SoftDrink} alt="Soft Drink Icon" />,
    },
    {
        id: 7,
        name: "Dessert",
        urlParam: 'Dessert',
        icon: <img src={Dessert} alt="Dessert" />,
    },
    {
        id: 8,
        name: "Condiments",
        urlParam: 'Condiments',
        icon: <img src={Condiments} alt="Condiments" />,
    },
    {
        id: 9,
        name:"Combo",
        urlParam: 'Combo',
        icon: <img src={Combo} alt="Combo Icon" />,
    }
];