import { MdLogin, MdOutlineKeyboardBackspace } from "react-icons/md";
import { motion } from "framer-motion";
import { MdShoppingBasket } from "react-icons/md";
import { useStateValue } from "../../context/StateProvider";
import { emptyCart, hideCart } from "../../utils/functions";
import { Link } from "react-router-dom";

const CartHeader = () => {
  const [{ user, cartItems, foodItems }, dispatch] = useStateValue();

  return (
    <div className="w-full flex items-center bg-gray-900 text-white justify-between px-4 py-2 cursor-pointer">
      <motion.div whileTap={{ scale: 0.8 }} onClick={() => hideCart(dispatch)}>
        <MdOutlineKeyboardBackspace className="text-white text-2xl" />
      </motion.div>

      <div className="flex items-center justify-center gap-2 mt-8">
        Cart
        <MdShoppingBasket className="text-xl cursor-pointer text-yellow-400" />
      </div>

      {!user && (
        <Link to={`/login`} onClick={() => hideCart(dispatch)}>
          <motion.p
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 0.9 }}
            className="flex items-center justify-center gap-2 p-1 px-2 my-2 bg-gray-700 rounded-md hover:shadow-sm text-white text-base"
          >
            <MdLogin className="text-yellow-400" /> Login to cart
          </motion.p>
        </Link>
      )}
    </div>
  );
};

export default CartHeader;
