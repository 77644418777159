import React from "react";
import { motion } from "framer-motion";
import burger from "../../img/burger.png";

const Right = () => {
  return (
    <div className="py-4 flex-1 flex items-center justify-center relative">
      <motion.img 
        src={burger}
        alt="Delicious Burger" 
        className='w-[80%] h-auto lg:h-[500px] lg:w-[500px] object-contain drop-shadow-xl'
        initial={{ scale: 0.8, opacity: 0, rotate: 0 }}
        animate={{ 
          scale: 1, 
          opacity: 1, 
          rotate: 0,
          y: [0, -10, 0], // Floating effect
          transition: {
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "mirror"
          }
        }}
        whileHover={{ 
          scale: 1.1,  // Slightly grow the image
          rotate: 10,  // Add a slight rotation
          filter: "brightness(1.3) contrast(1.2)", // Enhance brightness and contrast
          transition: { 
            duration: 0.5, 
            ease: "easeInOut"
          }
        }}
        whileTap={{ scale: 0.9 }} 
        style={{ 
          cursor: 'pointer',
          willChange: 'transform' // Helps with performance for animated elements
        }}
      />
    </div>
  );
};

export default Right;
