import React, { useState } from 'react';
import axios from 'axios';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import { APIURL } from '../../constant/APIURL';

const Feedback = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        rating: 0,
        message: ''
    });

    const handleChange = (e:any) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleRatingChange = (newRating:any) => {
        setFormData((prevState) => ({
            ...prevState,
            rating: newRating
        }));
    };

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        
        try {
            const response = await axios.post(`${APIURL}/feedback-contact`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                }
            }); 
            if (response.data.statusCode === 200) {
                toast.success('Feedback submitted successfully.');
                // Clear form fields
                setFormData({
                    name: '',
                    email: '',
                    rating: 0,
                    message: ''
                });
            } else {
                toast.error('Failed to submit feedback.');
            }
        } catch (error) {
            toast.error('Failed to submit feedback.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-900 py-12 md:py-16 lg:py-20">
            <div className="w-full max-w-3xl bg-gray-800 shadow-lg rounded-lg p-8 md:p-12 lg:p-16 space-y-8">
                <div className="space-y-4 text-center">
                    <h2 className="text-4xl font-bold text-white">Give Feedback</h2>
                    <p className="text-gray-400">
                        At Flavour Fusion, we are passionate about delivering the best burger experience. Your feedback helps us improve our service and menu. Please fill out the form below to share your thoughts.
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <div className="space-y-2">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-300">Name</label>
                            <input 
                                id="name" 
                                placeholder="Enter your name" 
                                className="w-full px-4 py-2 border border-gray-600 bg-gray-900 text-white rounded-md shadow-sm focus:ring focus:ring-blue-500" 
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="space-y-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
                            <input 
                                id="email" 
                                type="email" 
                                placeholder="Enter your email" 
                                className="w-full px-4 py-2 border border-gray-600 bg-gray-900 text-white rounded-md shadow-sm focus:ring focus:ring-blue-500" 
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="rating" className="block text-sm font-medium text-gray-300">Rating</label>
                        <StarRatings
                            rating={formData.rating}
                            starRatedColor="yellow"
                            changeRating={handleRatingChange}
                            numberOfStars={5}
                            name="rating"
                            starDimension="30px"
                            starSpacing="5px"
                        />
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="message" className="block text-sm font-medium text-gray-300">Feedback</label>
                        <textarea 
                            id="message" 
                            placeholder="Enter your message" 
                            className="w-full px-4 py-2 border border-gray-600 bg-gray-900 text-white rounded-md shadow-sm focus:ring focus:ring-blue-500 min-h-[150px]"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="w-full px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                        Send Feedback
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Feedback;
