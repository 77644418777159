// import { firebaseGetAllUsers } from "../Firebase";

export const fetchSessionUser = () => {
  const user = JSON.parse(JSON.stringify(localStorage.getItem("accessToken"))) || null;

  return user;
};
export const fetchSessionCart = () => {
  const cartInfo =
    localStorage.getItem("cartItems") !== "undefined"
      ? JSON.parse(localStorage.getItem("cartItems"))
      : localStorage.clear();

  return cartInfo ? cartInfo : [];
};

// session usermode
export const fetchSessionUserMode = () => {
  const adminMode =
    localStorage.getItem("userMode") !== "undefined"
      ? JSON.parse(localStorage.getItem("adminMode"))
      : localStorage.clear();

  return adminMode ? adminMode : false;
};
