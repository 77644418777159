import React, { useState } from 'react';
import { motion } from "framer-motion";
import axios from 'axios';
import Tiktok from '../../img/tiktok_2504942.png';
import Instagram from '../../img/instagram_4138124.png';
import Facebook from '../../img/facebook_2504903.png';
import { toast } from 'react-toastify';
import { APIURL } from '../../constant/APIURL';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e:any) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async (e:any) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${APIURL}/feedback-contact/contact`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                }
            });
            if (response.data.statusCode === 200) {
                toast.success('Message sent successfully.');
                // Clear form data
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            } else {
                toast.error('Failed to send message.');
            }
        } catch (error) {
            toast.error('Failed to send message.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-900 py-12 md:py-16 lg:py-20">
            <div className="w-full max-w-5xl bg-gray-800 shadow-lg rounded-lg p-8 md:p-12 lg:p-16 space-y-8">
                <div className="grid md:grid-cols-2 gap-12">
                    <div className="space-y-6">
                        <h2 className="text-4xl font-bold text-white">Get in touch</h2>
                        <p className="text-gray-300">
                            Have a concern or want to know more about our services? Feel free to contact us.
                        </p>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <div className="space-y-2">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-400">Name</label>
                                    <input
                                        id="name"
                                        placeholder="Enter your name"
                                        className="w-full px-4 py-2 border border-gray-600 bg-gray-900 text-white rounded-md shadow-sm focus:ring focus:ring-blue-500"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="space-y-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-400">Email</label>
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder="Enter your email"
                                        className="w-full px-4 py-2 border border-gray-600 bg-gray-900 text-white rounded-md shadow-sm focus:ring focus:ring-blue-500"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="subject" className="block text-sm font-medium text-gray-400">Subject</label>
                                <input
                                    id="subject"
                                    placeholder="Enter a subject"
                                    className="w-full px-4 py-2 border border-gray-600 bg-gray-900 text-white rounded-md shadow-sm focus:ring focus:ring-blue-500"
                                    value={formData.subject}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="message" className="block text-sm font-medium text-gray-400">Message</label>
                                <textarea
                                    id="message"
                                    placeholder="Enter your message"
                                    className="w-full px-4 py-2 border border-gray-600 bg-gray-900 text-white rounded-md shadow-sm focus:ring focus:ring-blue-500 min-h-[150px]"
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                            >
                                Send message
                            </button>
                        </form>
                    </div>
                    <div className="space-y-6">
                        <section className="space-y-4">
                            <h3 className="text-lg font-medium text-white">Address</h3>
                            <p className="text-gray-400">Eats On Oxford</p>
                            <p className="text-gray-400">32 Oxford Terrace, Christchurch Central City</p>
                            <p className="text-gray-400">Christchurch 8011</p>
                        </section>
                        <section className="space-y-4">
                            <h3 className="text-lg font-medium text-white">Phone</h3>
                            <p className="text-gray-400">
                                <a href="tel:0226399073" className="hover:underline">0226399073</a>
                            </p>
                        </section>
                        <section className="space-y-4">
                            <h3 className="text-lg font-medium text-white">Email</h3>
                            <p className="text-blue-500">
                                <a href="mailto:flavourfusionchch@gmail.com" className="hover:underline">flavourfusionchch@gmail.com</a>
                            </p>
                        </section>
                        <section className="space-y-4">
                            <h3 className="text-lg font-medium text-white">Social</h3>
                            <div className="flex space-x-6">
                                <motion.a
                                    whileTap={{ scale: 1.1 }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.tiktok.com/@flavourfusionchch?_t=8n1QLwxeXP6&_r=1"
                                    className="text-white h-10 w-10 bg-gray-900 rounded-full flex items-center justify-center"
                                >
                                    <img src={Tiktok} alt="TikTok" />
                                </motion.a>
                                <motion.a
                                    whileTap={{ scale: 1.1 }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.instagram.com/flavourfusionchch?igsh=NTc4MTIwNjQ2YQ=="
                                    className="text-white h-10 w-10 bg-gray-900 rounded-full flex items-center justify-center"
                                >
                                    <img src={Instagram} alt="Instagram" />
                                </motion.a>
                                <motion.a
                                    whileTap={{ scale: 1.1 }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.facebook.com/profile.php?id=61559883029659&mibextid=LQQJ4d"
                                    className="text-white h-10 w-10 bg-gray-900 rounded-full flex items-center justify-center"
                                >
                                    <img src={Facebook} alt="Facebook" />
                                </motion.a>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
