import { Link, useNavigate, useParams } from "react-router-dom";
import { ImageBox } from ".";
import { toast } from "react-toastify";
import { useState } from "react";
import axios from "axios";
import { APIURL } from "../../constant/APIURL";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams(); // Get token from URL params
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const resetPassword = async () => {
    try {
      const response = await axios.post(`${APIURL}/auth/user-reset-password`, {
        password,
        token
      });

      if (response.data.statusCode === 200) {
        toast.success("Password reset successfully");
        window.location.href = "/login";
      } else {
        toast.error("Password reset failed");
        // clear the password and confirm password fields
        setPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      toast.error("Something went wrong");
      setPassword("");
      setConfirmPassword("");
    }
  }

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Password and Confirm Password must be the same');
      return;
    }
    resetPassword();
  }

  return (
    <section className="w-full h-auto bg-gray-100">
      <div className="container mx-auto py-10 h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-3 text-gray-800">
          <ImageBox />
          <div className="w-full md:w-[30rem] bg-white rounded-lg shadow-lg p-8">
            <form onSubmit={handleResetPassword}>
              <h2 className="text-2xl font-bold text-center mb-6">Reset Password</h2>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Enter Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter a new password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                  Enter Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter a Confirm password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              </div>
              <div className="mt-6 text-center">
                <p className="text-sm text-gray-700">
                  Don't have an account?
                  <Link to={"/register"} className="text-blue-500 hover:text-blue-800 ml-1">
                    Sign Up
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
