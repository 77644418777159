import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeKey } from '../../constant/Stripe';
import Cart from './Item';

const stripePromise = loadStripe(StripeKey);

interface CartBodyProps {
  cartItems: any[];
  action: (state: boolean) => void;
  totalAmount: number;
}

const CartBody: React.FC<CartBodyProps> = ({ cartItems, action, totalAmount }) => {
  return (
    <div className="w-full h-full rounded-t-2xl bg-gray-800 text-white flex flex-col shadow-lg max-w-8xl mx-auto">
      <div className="w-full flex-grow px-8 py-10 flex flex-col gap-5" style={{ maxHeight: '500px', overflowY: 'auto' }}>
        {/* Use the combined Cart component instead of CartItem */}
        <Elements stripe={stripePromise}>
          <Cart cartItems={cartItems} checkoutState={action} />
        </Elements>
      </div>
    </div>
  );
};

export default CartBody;
