import "react-toastify/dist/ReactToastify.css";

import {
  About,
  Admin,
  Home,
  Login,
  Menu,
  Profile,
  Services,
  Signup,
} from "./Pages";
import { Cart, Footer, Header } from "./components";
import { Route, Routes } from "react-router-dom";
import {
  calculateCartTotal,
  dispatchUsers,
  fetchFoodData,
  fetchUserCartData,
  isAdmin,
} from "./utils/functions";

import { AnimatePresence } from "framer-motion";
import Contact from "./components/Contact";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useStateValue } from "./context/StateProvider";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import EmailVerificationPage from "./components/Verification/verification";
import Thankyou from "./Pages/Thankyou/thankyou";
import OrderHistory from "./Pages/OrderHistory/orderHistory";
import PayementFailed from "./Pages/Thankyou/paymentFail";
import ContactUs from "./components/Contact/ContactUs";
import Feedback from "./components/Contact/Feedback";

function App() {
  const [
    { showCart, showContactForm, user, foodItems, cartItems, adminMode },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    fetchFoodData(dispatch);
    dispatchUsers(dispatch);
    user && fetchUserCartData(user, dispatch);
  }, [user, dispatch]);

  useEffect(() => {
    foodItems &&
      cartItems.length > 0 &&
      calculateCartTotal(cartItems, foodItems, dispatch);
  }, [cartItems, foodItems, dispatch]);
  return (
    <AnimatePresence exitBeforeEnter>
      <ToastContainer />
      <div className="w-screen h-auto min-h-[100vh] flex flex-col bg-primary">
        {showCart && <Cart />}
        {!(adminMode && isAdmin(user)) && <Header />}
        <main
          className={`${!(adminMode && isAdmin(user)) &&
            "mt-16 md:mt-16 px-3 md:px-8 md:py-6 py-4"
            } w-full h-auto`}
          onClick={() => { }}
        >
          {/* Routes */}
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/email-verification" element={<EmailVerificationPage />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/about" element={<About />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/services" element={<Services />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/failed" element={<PayementFailed />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/feedback" element={<Feedback />} />
          </Routes>

          {!(adminMode && isAdmin(user)) && <Footer />}
        </main>
      </div>
    </AnimatePresence>
  );
}

export default App;
