import { FoodItem, cartItem } from "../../types";
import { MdShoppingBasket } from "react-icons/md";
import { toast } from "react-toastify";
import axios from 'axios';
import { APIURL } from "../constant/APIURL";
import { getUserIdFromToken } from "./auth";

export const addToCart = async (
  cartItems: cartItem[] | undefined,
  foodItems: FoodItem[],
  user: any,
  food: FoodItem,
  dispatch: any
) => {
  if (!user) {
    toast.error("Please login to add items to cart", {
      icon: <MdShoppingBasket className="text-2xl text-cartNumBg" />,
      toastId: "unauthorizedAddToCart",
    });
    return;
  }

  if (!cartItems) {
    cartItems = [];
  }

  const userId = getUserIdFromToken();
  if (cartItems.length > 0) {
    // Create a new cart item
    const newItem: cartItem = {
      id: Date.now(),
      fid: food.id,
      uid: userId,
      qty: 1,
    };

    // Update the local state
    dispatch({
      type: "SET_CARTITEMS",
      cartItems: [...cartItems, newItem],
    });

    calculateCartTotal([...cartItems, newItem], foodItems, dispatch);
  };



  // Send the item to the backend
  try {
    await axios.post(`${APIURL}/cart`, {
      userId: userId,
      menuId: food.id,
      quantity: 1,
    },{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    
    });
    toast.success("Item added to cart");
  } catch (error) {
    console.error("Error adding item to cart", error);
    toast.error("Error adding item to cart");
  }
};



export const dispatchtUserCartItems = (
  uid: string,
  items: cartItem[],
  dispatch: any
) => {
  const cartItems = items.filter((item: cartItem) => item.uid === uid);
  dispatch({
    type: "SET_CARTITEMS",
    cartItems: cartItems,
  });

  return cartItems;
};

export const fetchUserCartData = async (user: any, dispatch: any) => {
  if (user) {

  } else {
    localStorage.setItem("cartItems", "undefined");
  }
};

export const fetchFoodData = async (dispatch: any) => {
 
};
export const getFoodyById = (menu: FoodItem[], fid: number) => {
  // return menu.find((item: FoodItem) => item.id === fid);
};

//  Update cart item State
export const updateCartItemState = async (
  cartItems: cartItem[],
  item: cartItem,
  dispatch: any
) => {
  const index = cartItems.findIndex(
    (cartItem: cartItem) => cartItem.id === item.id
  );
  if (index !== -1) {
    cartItems[index] = item;
  }
  dispatch({
    type: "SET_CARTITEMS",
    cartItems: cartItems,
  });

};

// Update Cart Item Quantity
export const updateCartItemQty = async (
  cartItems: cartItem[],
  foodItems: FoodItem[],
  item: cartItem,
  dispatch: any,
  change: number
) => {
  // const index = cartItems.findIndex(
  //   (cartItem: cartItem) => cartItem.id === item.id
  // );
  // if (index !== -1) {
  //   cartItems[index].qty += val;
  //   if (cartItems[index].qty < 1) {
  //     cartItems[index].qty = 1;
  //   }
  //   dispatch({
  //     type: "SET_CARTITEMS",
  //     cartItems: [...cartItems],
  //   });
  const cartItem = cartItems.find((cartItem: cartItem) => cartItem.id === item.id);
  if (!cartItem) return;

  const newQty = cartItem.qty + change;

  if (newQty < 1) {
    dispatch({ type: 'REMOVE_CART_ITEM', id: item.id });
  } else {
    dispatch({ type: 'UPDATE_CART_ITEM', id: item.id, qty: newQty });
  }

  calculateCartTotal(cartItems, foodItems, dispatch);
}


//  Delete Cart Item
export const deleteCartItem = async (
  cartItems: cartItem[],
  foodItems: FoodItem[],
  item: any,
  dispatch: any
) => {
  const index = cartItems.findIndex(
    (cartItem: cartItem) => cartItem.id === item.id
  );
  if (index !== -1) {
    try {

      await axios.delete(`${APIURL}/cart/${item.cartId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }
      );
      cartItems.splice(index, 1);

      dispatch({
        type: 'SET_CARTITEMS',
        cartItems: cartItems
      });

      calculateCartTotal(cartItems, foodItems, dispatch);
    } catch (error) {
      toast.error("Error deleting item from cart");
    }
  }
};

// Calculate Total Price Round to 2 decimal places
export const calculateCartTotal = (
  cartItems: cartItem[],
  foodItems: FoodItem[],
  dispatch: any
) => {
  let total = 0;
  cartItems.forEach((item: cartItem) => {
    const foodItem = getFoodyById(foodItems, item.fid);
    // total += item.qty * parseFloat(foodItem?.price || "0");
  });
  dispatch({
    type: "SET_CART_TOTAL",
    cartTotal: total.toFixed(2),
  });
};

// Empty Cart
export const emptyCart = async (
  cartItems: cartItem[],
  foodItems: FoodItem[],
  dispatch: any
) => {
  if (cartItems.length > 0) {
    dispatch({
      type: "SET_CARTITEMS",
      cartItems: [],
    });
    calculateCartTotal(cartItems, foodItems, dispatch);
  } else {
    toast.warn("Cart is already empty");
  }
};

// Hide Cart
export const hideCart = (dispatch: any) => {
  dispatch({
    type: "TOGGLE_CART",
    showCart: !true,
  });
};

// Hide Cart
export const hideContactform = (dispatch: any) => {
  dispatch({
    type: "TOGGLE_CONTACT_FORM",
    showContactForm: !true,
  });
};

export const shuffleItems = (items: any) => {
  let currentIndex = items.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [items[currentIndex], items[randomIndex]] = [
      items[randomIndex],
      items[currentIndex],
    ];
  }

  return items;
};

export const logout = async (user: any, dispatch: any, navigate: any) => {
  if (user) {

  } else {
  }
};

export const ToggleAdminMode = (dispatch: any, state: boolean) => {
  dispatch({
    type: "SET_ADMIN_MODE",
    adminMode: state,
  });
  localStorage.setItem("adminMode", JSON.stringify(state));
};

export const isAdmin = (user: any) => {
  return user?.email;
};

// get user
export const getUserData = async (user: any) => {
  // return await firebaseGetUser(user.uid);
};

// update currentUser
export const updateUserData = async (
  user: any,
  dispatch: any,
  alert: boolean
) => {
  // await firebaseUpdateUser(user)
  //   .then(() => {
  //     dispatch({
  //       type: "SET_USER",
  //       user: user,
  //     });
  //   })
  //   .catch((e: any) => {
  //     console.log(e);
  //   })
  //   .then(() => {
  //     localStorage.setItem("user", JSON.stringify(user));
  //     alert && toast.success("User data updated successfully");
  //   });
};

// get all users
export const dispatchUsers = async (dispatch: any) => {
  // await firebaseGetAllUsers()
  //   .then((users: any) => {
  //     dispatch({
  //       type: "SET_USERS",
  //       users: users,
  //     });
  //   })
  //   .catch((e: any) => {
  //     console.log(e);
  //   });
};
export const getAllUser = async () => {
  // await firebaseGetAllUsers()
  //   .then((users: any) => {
  //     return users;
  //   })
  //   .catch((e: any) => {
  //     console.log(e);
  //   });
};
// delete food
export const deleteFood = async (
  food: FoodItem,
  foodItems: FoodItem[],
  dispatch: any
) => {
  // await firebaseDeleteFood(food.id);
  // remove food from foodItems
  const foodIndex = foodItems.indexOf(food);
  if (foodIndex !== -1) {
    foodItems.splice(foodIndex, 1);
  }
  dispatch({
    type: "SET_FOOD_ITEMS",
    foodItems,
  });
  toast.success("Food deleted successfully");
};
