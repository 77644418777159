import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { ImageBox } from ".";
import { toast } from "react-toastify";
import { APIURL } from "../../constant/APIURL";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState<string>("");

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const response = await axios.post(
        `${APIURL}/auth/user-forgot-password`,
        {
          email,
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );  

      if (response.data.statusCode === 200) {
        setMessage("Reset link sent to your email.");
        window.location.href = "/login";

      } else {
        setError("An error occurred. Please try again.");
        setEmail("");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      setEmail("");
    }
  };

  return (
    <section className="w-full h-auto bg-gray-100">
      <div className="container mx-auto py-10 h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-3 text-gray-800">
          <ImageBox />
          <div className="w-full md:w-[30rem] bg-white rounded-lg shadow-lg p-8">
            <form onSubmit={handleForgotPassword}>
              <h2 className="text-2xl font-bold text-center mb-6">
                Forgot Password
              </h2>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email address
                </label>
                <input
                  type="email"
                  id="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Send Reset Link
                </button>
              </div>
              {message && (
                <p className="text-green-500 text-center mt-4">{message}</p>
              )}
              {error && (
                <p className="text-red-500 text-center mt-4">{error}</p>
              )}
              <div className="mt-6 text-center">
                <p className="text-sm text-gray-700">
                  Don't have an account?
                  <Link
                    to={"/register"}
                    className="text-blue-500 hover:text-blue-800 ml-1"
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
