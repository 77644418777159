import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

export const getUserIdFromToken = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userId; 
    } catch (error) {
      toast.error("Invalid token");
      return null;
    }
  }
  return null;
};


