import { Link, useNavigate } from "react-router-dom";
import { ImageBox } from ".";
import { toast } from "react-toastify";
import { useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { signUp } from "../../service/Auth/authService";
import { getUserIdFromToken } from "../../utils/auth";

const Register = () => {
  const userId = getUserIdFromToken()
  if (userId) {
    window.location.href = '/';
  }

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [phone, setPhone] = useState()
  const [subscribe, setSubscribe] = useState(false);

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const data = { name, email, password, userRole: 'USER', phone: '', gender, dateOfBirth: dob , isEmailSubscribed: subscribe};
      await signUp(data);
      toast.success("Signup successful");
      navigate('/');
      window.location.reload();
    } catch (error) {
      toast.error("Signup failed");
    }
  }


  return (
    <section className="w-full h-auto bg-gray-900 text-white">
      <div className="container mx-auto py-10 h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-3">
          <ImageBox />
          <div className="w-full md:w-[30rem] bg-gray-800 rounded-lg shadow-lg p-8">
            <form onSubmit={handleFormSubmit}>
              <h2 className="text-3xl font-bold text-center mb-6">Register Your Account</h2>
              <div className="mb-6">
                <label className="block text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-white bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="text"
                  id="email"
                  className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-white bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-bold mb-2" htmlFor="password">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-white bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-bold mb-2" htmlFor="phone">
                  Phone Number
                </label>
                <PhoneInput
                  className="border border-gray-600 rounded-md p-2 text-white bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter phone number"
                  value={phone as any}
                  onChange={(value?: any | undefined) => setPhone(value?.number || '')}
                  defaultCountry="NZ"
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-bold mb-2" htmlFor="gender">
                  Gender
                </label>
                <select
                  className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-white bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="mb-6">
                <label className="block text-sm font-bold mb-2" htmlFor="dob">
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="dob"
                  className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-white bg-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
              <div className="mb-6 p-4 bg-gray-800 rounded-md shadow-md">
                <label htmlFor="subscribe" className="block text-sm font-bold mb-2 flex items-center">
                  <input
                    type="checkbox"
                    id="subscribe"
                    className="form-checkbox h-5 w-5 text-indigo-500 mr-2"
                    onChange={(e) => setSubscribe(e.target.checked)}
                  />
                  Subscribe to our advertising emails
                </label>
                <p className="text-xs text-gray-400 mt-2">
                  Stay updated with our latest promotions and offers.
                </p>
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleFormSubmit}
                >
                  Sign Up
                </button>
              </div>
              <div className="mt-6 text-center">
                <p className="text-sm text-gray-400">
                  Do you have an account?
                  <Link to={"/login"} className="text-indigo-500 hover:text-indigo-700 ml-1">
                    Sign In
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
