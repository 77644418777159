import React, { useState, useEffect, useMemo, useRef } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useCookies } from 'react-cookie';
import axios from "axios";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { getUserIdFromToken } from "../../utils/auth";
import { APIURL } from "../../constant/APIURL";
import { StripeKey } from "../../constant/Stripe";

const stripePromise = loadStripe(StripeKey);

interface AddonType {
  id: string;
  name: string;
  price: number;
  quantity: number;
}

interface CartItemType {
  id?: number;
  name?: string;
  menuId: number;
  menuName: string;
  price: number;
  quantity: number;
  imageUrl: string;
  addons: AddonType[];
  bunOption?: string;
  serveType?: string;
  note?: string;
  cartId?: number;
}

interface CartProps {
  cartItems: CartItemType[];
  checkoutState: (state: boolean) => void;
}

const Cart = ({ cartItems, checkoutState }: CartProps) => {
  const [cartData, setCartData] = useState<CartItemType[]>(cartItems);
  const [promoCode, setPromoCode] = useState<string>("");
  const [discount, setDiscount] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [addonsVisible, setAddonsVisible] = useState<{ [key: string]: boolean }>({});
  const [cookies, setCookie, removeCookie] = useCookies(['cart']);
  const userIdRef = useRef(getUserIdFromToken());

  useEffect(() => {
    setCartData(cartItems);
  }, [cartItems]);

  const incrementQuantity = (menuId: number) => {
    const updatedCartData = cartData.map(item =>
      item.menuId === menuId ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartData(updatedCartData);
  };

  const decrementQuantity = (menuId: number) => {
    const updatedCartData = cartData.map(item =>
      item.menuId === menuId ? { ...item, quantity: Math.max(item.quantity - 1, 1) } : item
    );
    setCartData(updatedCartData);
  };

  const incrementAddonQuantity = (menuId: number, addonId: string) => {
    const updatedCartData = cartData.map(item => {
      if (item.menuId === menuId) {
        const updatedAddons = item.addons.map(addon => {
          if (addon.id === addonId) {
            // Start quantity at 1 if it's currently 0 or undefined
            const newQuantity = addon.quantity > 0 ? addon.quantity + 1 : 1;
            return { ...addon, quantity: newQuantity };
          }
          return addon;
        });
        return { ...item, addons: updatedAddons };
      }
      return item;
    });
    setCartData(updatedCartData);
  };

  const decrementAddonQuantity = (menuId: number, addonId: string) => {
    const updatedCartData = cartData.map(item => {
      if (item.menuId === menuId) {
        const updatedAddons = item.addons.map(addon => {
          const newQuantity = Math.max(addon.quantity - 1, 1); // Ensure the quantity doesn't go below 1
          return addon.id === addonId ? { ...addon, quantity: newQuantity } : addon;
        });
        return { ...item, addons: updatedAddons };
      }
      return item;
    });
    setCartData(updatedCartData);
  };

  const deleteCartItem = async (menuId: number) => {
    const cartDataInCookie = cookies.cart?.data || [];
    if (cartDataInCookie.length > 0) {
      const updatedCartDataInCookie = cartDataInCookie.filter((item: any) => item.menuId !== menuId);
      if (updatedCartDataInCookie.length > 0) {
        setCookie('cart', { data: updatedCartDataInCookie }, { path: '/' });
      } else {
        removeCookie('cart', { path: '/' });
      }
      setCartData(updatedCartDataInCookie);
      toast.success("Item removed successfully");
    } else {
      try {
        const response = await axios.delete(`${APIURL}/cart/${menuId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        });
        if (response.data.statusCode === 200) {
          const updatedCartData = cartData.filter(cartItem => cartItem.menuId !== menuId);
          setCartData(updatedCartData); // Update state after deleting item
          toast.success("Item deleted successfully");
        } else {
          toast.error("Error deleting item from server");
        }
      } catch (error) {
        toast.error("Error deleting item from server");
      }
    }
  };

  const toggleAddons = (menuId: number) => {
    setAddonsVisible(prevState => ({
      ...prevState,
      [menuId]: !prevState[menuId]
    }));
  };

  const applyPromoCode = async () => {
    try {
      const response = await axios.get(`${APIURL}/promo-code/${promoCode}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
      });
      const promoData = response.data.data;

      if (!validatePromoCode(promoData)) {
        setPromoCode("");
        return; // Exit if validation fails
      }

      // Calculate discount based on type
      const discountValue = promoData.discountType === 'percentage'
        ? calculateTotal() * promoData.discountAmount / 100
        : promoData.discountAmount;

      setDiscount(discountValue);
      setDiscountType(promoData.discountType);
      toast.success("Promo code applied successfully");
      setPromoCode(""); // Reset promo code input
    } catch (error) {
      toast.error("Error applying promo code");
      console.error(error);
    }
  };

  const validatePromoCode = (promoData: any) => {
    const currentDate = moment();
    const validityDate = moment(promoData.validityPeriod);

    if (!promoData.status) {
      toast.error("Promo code is not active");
      return false;
    }
    if (promoData.numberOfUsage >= promoData.usageLimit) {
      toast.error("Promo code has reached usage limit");
      return false;
    }
    if (calculateTotal() < promoData.minOrderAmount) {
      toast.error(`Minimum order amount for this promo code is $${promoData.minOrderAmount}`);
      return false;
    }
    if (currentDate > validityDate) {
      toast.error("Promo code has expired");
      return false;
    }
    return true;
  };

  const calculateTotal = () => {
    return cartData.reduce((total, item) => {
      // Safely parse item price and quantity
      const itemPrice = parseFloat(item.price?.toString() || "0");
      const itemQuantity = parseInt(item.quantity?.toString() || "0");

      // Calculate the total for the item itself
      const itemTotal = itemPrice * itemQuantity;

      // Calculate the total for the item's addons
      const addonsTotal = item.addons.reduce((addonTotal, addon) => {
        const addonPrice = parseFloat(addon.price?.toString() || "0");
        const addonQuantity = parseInt(addon.quantity?.toString() || "0");
        return addonTotal + addonPrice * addonQuantity;
      }, 0);


      // Add the item's total and the addons' total to the overall total
      return total + itemTotal + addonsTotal;
    }, 0);
  };


  const discountedTotal = useMemo(() => {
    return discountType === 'percentage' ? calculateTotal() - discount : calculateTotal() - discount;
  }, [cartData, discount, discountType]);


  const handleSubmit = async () => {
    setLoading(true);
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error("Stripe not loaded");


      const response = await axios.post(`${APIURL}/order`, {
        userId: userIdRef.current,
        orderDate: new Date(),
        cartId: cartData[0]?.cartId,
        menuItems: cartData.map(item => ({
          menuId: item.menuId,
          quantity: item.quantity
        })),
        TotalAmount: calculateTotal(),
        addons: cartData.map(item => item.addons),
        status: 'pending',
        payment_method: 'card',
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      });

      await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });
    } catch (error) {
      console.error("Error during checkout", error);
      toast.error("Checkout failed, please try again.");
    } finally {
      setLoading(false);
      checkoutState(false);
    }
  };

  return (
    <div className="cart-container bg-gray-900 text-white">
      {/* Cart Items */}
      <div className="cart-items space-y-6">
        {cartData.map((cartItem, index) => (
          <div key={`${cartItem.menuId}-${index}`} className="cart-item flex gap-4 p-4 bg-gray-800 rounded-lg shadow-md">
            <img src={cartItem.imageUrl} alt={cartItem.menuName} className="w-28 h-28 rounded-lg object-cover shadow-md" />
            <div className="flex flex-col w-full">
              <div className="flex justify-between w-full">
                <div className="flex flex-col md:flex-row items-start md:items-center">
                  <div className="text-white font-semibold text-xl">{cartItem.menuName}</div>
                </div>
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  className="p-2 rounded-full bg-red-500 flex items-center justify-center cursor-pointer hover:bg-red-600 transition-colors duration-300"
                  onClick={() => deleteCartItem(cartItem.menuId)}
                >
                  <AiOutlineDelete className="text-white" size={24} />
                </motion.div>
              </div>
              <div className="text-gray-400 text-lg md:ml-4">
                <span className="text-red-500">$</span>
                {cartItem.price}
              </div>
              <div className="flex items-center gap-2 mt-2">
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  onClick={() => decrementQuantity(cartItem.menuId)}
                  className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors duration-300"
                >
                  <BiMinus className="text-white cursor-pointer" />
                </motion.div>
                <p className="text-lg text-white w-10 h-10 rounded-md flex items-center justify-center bg-gray-700 shadow-md">
                  {cartItem.quantity}
                </p>
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  onClick={() => incrementQuantity(cartItem.menuId)}
                  className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors duration-300"
                >
                  <BiPlus className="text-white cursor-pointer" />
                </motion.div>
              </div>
              <div className="mt-2 p-4 border border-gray-700 rounded-lg shadow-sm bg-gray-800">
                {cartItem.bunOption && (
                  <div className="text-gray-400 text-sm mb-1">
                    <span className="font-semibold text-white">Bun: </span>
                    <span>{cartItem.bunOption}</span>
                  </div>
                )}
                {cartItem.serveType && (
                  <div className="text-gray-400 text-sm mb-1">
                    <span className="font-semibold text-white">Serve: </span>
                    <span>{cartItem.serveType}</span>
                  </div>
                )}
                {cartItem.note && (
                  <div className="text-gray-400 text-sm">
                    <span className="font-semibold text-white">Notes: </span>
                    <span>{cartItem.note}</span>
                  </div>
                )}
              </div>
              {cartItem.addons && cartItem.addons.length > 0 && (
                <div className="mt-2">
                  <button
                    onClick={() => toggleAddons(cartItem.menuId)}
                    className="text-sm text-gray-400 hover:text-white focus:outline-none flex items-center"
                  >
                    {addonsVisible[cartItem.menuId] ? 'Hide Addons' : 'Show Addons'}
                    <svg
                      className={`ml-1 w-4 h-4 transition-transform duration-200 ${addonsVisible[cartItem.menuId] ? 'rotate-180' : 'rotate-0'}`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {addonsVisible[cartItem.menuId] && (
                    <div className="mt-2 bg-gray-900 p-4 rounded-lg shadow-inner space-y-2 border border-gray-700">
                      {cartItem.addons.map((addon: AddonType) => (
                        <div key={`${cartItem.menuId}-${addon.id}`} className="flex justify-between items-center text-white py-2 px-4 bg-gray-800 rounded-lg shadow-sm">
                          <span className="flex-grow">{addon.name}</span>
                          <span className="mx-2">${addon.price?.toFixed(2)}</span>
                          <div className="flex items-center gap-2">
                            <motion.div
                              whileTap={{ scale: 0.9 }}
                              onClick={() => decrementAddonQuantity(cartItem.menuId, addon.id)}
                              className="p-1 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors duration-300"
                            >
                              <BiMinus className="text-white cursor-pointer" />
                            </motion.div>
                            <span>{addon.quantity}</span>
                            <motion.div
                              whileTap={{ scale: 0.9 }}
                              onClick={() => incrementAddonQuantity(cartItem.menuId, addon.id)}
                              className="p-1 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors duration-300"
                            >
                              <BiPlus className="text-white cursor-pointer" />
                            </motion.div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Cart Total */}
      <div className="fixed bottom-0 bg-gray-800 shadow-lg px-7 py-2 rounded-t-3xl">
        <div className="grid grid-cols-3 gap-2 items-center">
          <input
            type="text"
            placeholder="Promo Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            className="col-span-2 p-2 border rounded-lg focus:outline-none focus:border-blue-500 transition-all bg-gray-700 text-white placeholder-gray-400"
          />
          <button
            onClick={applyPromoCode}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-lg transition-colors duration-200"
          >
            Apply
          </button>
        </div>
        <div className="text-white text-sm flex justify-between my-2">
          <span>Sub Total</span>
          <span>
            <span className="text-red-500">$</span>{calculateTotal().toFixed(2)}
          </span>
        </div>
        <div className="text-white text-sm flex justify-between">
          <span>Discount</span>
          <span>
            <span className="text-red-500">$</span>{discount?.toFixed(2)}
          </span>
        </div>
        <div className="text-sm font-bold flex justify-between my-1 text-white">
          <span className="uppercase">Total</span>
          <span>
            <span className="text-red-500">$</span>{discountedTotal.toFixed(2)}
          </span>
        </div>
        <button
          onClick={handleSubmit}
          disabled={loading}
          className={`w-full py-2 rounded-lg ${loading ? "bg-gray-400" : "bg-green-500 hover:bg-green-600"} text-white font-bold mt-2 transition-colors duration-300`}
        >
          {loading ? "Processing..." : `Checkout $${discountedTotal.toFixed(2)}`}
        </button>
      </div>
    </div>
  );
};

export default Cart;