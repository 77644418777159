import { Link } from "react-router-dom";
import { MdShoppingBasket } from "react-icons/md";
import { motion } from "framer-motion";
import { useStateValue } from "../../context/StateProvider";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constant/APIURL";

const Navigations = ({ direction }: { direction?: string }) => {
  const [{ user }] = useStateValue();

  const [{ cart }, dispatch] = useStateValue();
  const [cartItemCount, setCartItemCount] = useState(0);

 

  useEffect(() => {
      const token = localStorage.getItem('accessToken');
      if(token){
      const fetchUserData = async () => {
        const response = await axios.get(`${APIURL}/me`,{
          headers:{
            Authorization: `Bearer ${user}`
          }
        })
        setCartItemCount(response?.data?.isCartItem)
        // setCartItemCount(response.data.cart.length)
      }
    

      fetchUserData();
    }
  }, [user]);


  return (
    <div className="flex items-center gap-8">
      <motion.ul
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 200 }}
        className={`flex items-center gap-8 ${direction && direction}`}
      >
        <motion.li
          whileHover={{ scale: 1.1 }}
          className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
        >
          <Link to="/">Home</Link>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
        >
          <Link to="/menu">Menu</Link>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
        >
          <Link to="/services">Services</Link>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
        >
          <Link to="/about">About us</Link>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
        >
          <Link to="/contact">Contact us</Link>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          className="md:text-sm lg:text-md text-base text-textColor cursor-pointer hover:text-headingColor duration-100 transition-all ease-in-out"
        >
          <Link to="/feedback">Feedback</Link>
        </motion.li>
      </motion.ul>

      <motion.div
        whileTap={{ scale: 0.9 }}
        whileHover={{ scale: 1.1 }}
        className="relative flex items-center justify-center text-textColor"
        onClick={() =>
          dispatch({
            type: "TOGGLE_CART",
            showCart: true,
          })
        }
      >
        <MdShoppingBasket className="text-2xl cursor-pointer" />
        
          <div className="absolute -top-2 -right-2 w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center cursor-pointer">
            <p className="text-sm text-white font-semibold">
              {cartItemCount}
            </p>
          </div>
              </motion.div>
    </div>
  );
};

export default Navigations;
