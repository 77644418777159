import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";

type Testimonial = {
  id: string;
  name: string;
  message: string;
  imageUrl: string;
  rating: number;
};

const Testimonials = ({ testimonials }: { testimonials: Testimonial[] }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  useEffect(() => {
    const slideWidth = sliderRef.current?.offsetWidth || 0;
    const totalWidth = sliderRef.current?.scrollWidth || 0;

    const slide = () => {
      controls.start({
        x: [0, -totalWidth + slideWidth],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: "mirror",
            duration: totalWidth / 100, // Adjust duration according to width
            ease: "linear"
          }
        }
      });
    };

    slide();
  }, [controls]);

  return (
    <section className="w-full py-16 bg-gray-900 overflow-hidden">
      <h2 className="text-4xl text-center text-white font-bold mb-12">What Our Customers Say</h2>
      <div className="container mx-auto px-4">
        <motion.div
          ref={sliderRef}
          className="flex gap-8"
          animate={controls}
        >
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              className="min-w-[300px] bg-gray-800 rounded-xl shadow-lg p-6 flex-shrink-0"
            >
              <p className="text-gray-400 italic">"{testimonial.message}"</p>
              <h3 className="mt-4 text-2xl text-white font-semibold">{testimonial.name}</h3>
              <p className="text-yellow-500">{testimonial.rating} ★</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Testimonials;
