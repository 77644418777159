import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useStateValue } from "../../context/StateProvider";
import CartBody from "./Body";
import CartHeader from "./Header";
import { motion } from "framer-motion";
import EmptyCart from "../EmptyCart";
import NotFound from "../NotFound";
import Checkout from "../Checkout";
import { APIURL } from "../../constant/APIURL";
import { getUserIdFromToken } from "../../utils/auth";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { createCart } from "../../service/cart/cartService";

const Cart = () => {
  const [{ cartItems }, dispatch] = useStateValue();
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const effectRun = useRef(false);
  const [cookies, setCookie, removeCookie] = useCookies(["cart"]);
  const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cartId, setCartId] = useState(null); // Add cartId state

  const userId = getUserIdFromToken();

  const fetchCartItemsFromAPI = async () => {
    try {
      let cartInfo;
      if (cookies.cart) {
        cartInfo = cookies.cart.data;
        let total = 0;
        for (const cookieItem of cartInfo) {
          const cart = {
            userId: userId,
            menuId: cookieItem.menuId,
            quantity: cookieItem.quantity,
            price: cookieItem.price,
            bunOption: cookieItem.bunOption,
            serveType: cookieItem.serveType,
            addons: cookieItem.addons,
            note: cookieItem.note,
            totalAmount: cookies.cart.totalAmount,
          };
          total += cookieItem.quantity * cookieItem.price;

          try {
            if (userId) {
              const cartResponse = await createCart(cart);
              setCartId(cartResponse.data.cartId); // Capture cartId from the response

              // Clear cart cookie
              removeCookie('cart', { path: '/' });
            }
          } catch (error) {
            console.error("Error adding item to cart:", error);
          }
        }
        setTotalAmount(total);
      } else {
        const response = await axios.get(`${APIURL}/cart/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        const { totalAmount, data: cartItems, cartId } = response.data;
        cartInfo = cartItems;
        setTotalAmount(totalAmount);
        setCartId(cartId);
      }
      dispatch({ type: 'UPDATE_CART_ITEMS', cartItems: cartInfo });
      setCartData(cartInfo);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (!effectRun.current) {
      if (cookies.cart || userId) {
        fetchCartItemsFromAPI();
      }
      effectRun.current = true;
    }
  }, []);

  return (
    <>
      {checkoutOpen ? (
        <Checkout handler={setCheckoutOpen} />
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            className="w-full h-screen md:w-[350px] bg-gray-900 text-white md:backdrop-blur-sm flex flex-col z-[101] drop-shadow-xl fixed top-0 right-0"
          >
            <CartHeader />
            {cartData && cartData.length > 0 ? (
              <CartBody cartItems={cartData} action={setCheckoutOpen} totalAmount={totalAmount} />
            ) : (
              <div className="h-full w-full flex-1 flex items-center justify-center">
                <EmptyCart />
              </div>
            )}
          </motion.div>
          {(!cartData || cartData.length === 0) && (
            <NotFound text={"Cart Items not available"} />
          )}
        </>
      )}
    </>
  );
};

export default Cart;
