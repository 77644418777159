import "react-toastify/dist/ReactToastify.css";
import Logo from "../../img/logo2test.png";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useStateValue } from "../../context/StateProvider";

const ProviderAuth = () => {

  const [{ user }, dispatch] = useStateValue();
  const navigate = useNavigate();

  const AUTH = async ({ provider }: { provider: any }) => {
    if (!user) {
      
    }
  };
  return (
    <div className="flex items-center justify-center gap-5  text-center">
      
      
    </div>
  );
};

export const ImageBox = () => {
  return (
    <div className="hidden md:w-8/12 lg:w-6/12 mb-12 md:mb-0 md:flex ">
      <motion.img
        whileHover={{
          rotate: [0, -10, 10, -10, 0],
          // duration: 0.5,
        }}
        src={Logo}
        className="w-96 cursor-pointer"
        alt="logo-login"
      />
    </div>
  );
};

export default ProviderAuth;
