import { useEffect, useRef, useState } from "react";
import { FruitsSection, MenuSection, ShowcaseBanner, } from "../../components"
import { APIURL } from "../../constant/APIURL";
import axios from "axios";
import { FoodItem } from "../../../types";
import { toast } from "react-toastify";
import Testimonials from "../../components/Testimonials/Testimonials";
import { text } from "stream/consumers";

const Home = () => {
  const [items, setItems] = useState<FoodItem[]>([]);
  const effectRun = useRef(false);

  useEffect(() => {
    if (effectRun.current === false) {
      const fetchItems = async () => {
        try {
          const response = await axios.get(`${APIURL}/menu-item/menu/all`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
          });
          setItems(response.data.menuList);
        } catch (error) {
          toast.error("Fetching item failed");
        }
      };

      fetchItems();
      effectRun.current = true;
    }
    return () => {
      effectRun.current = true;
    };
  }, []);

  const exampleTestimonials:any = [
    {name: "John Doe", message: "This is a great place to eat",  rating: 5},
    {name: "Jane Doe", message: "I love the food here",  rating: 4},
    {name: "Alice Doe", message: "I love the food here", rating: 4},
    {name: "Bob Doe", message: "I love the food here", rating: 4},
    {name: "Charlie Doe", message: "I love the food here", rating: 4},
    {name: "David Doe", message: "I love the food here", rating: 4},
    



  ];

  return (
    <div className="flex w-full h-auto flex-col items-center justify-center">

      <ShowcaseBanner />
      <FruitsSection data={items} />
      <Testimonials testimonials={exampleTestimonials} />


    </div>
  );
};

export default Home;