import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { APIURL } from '../../constant/APIURL';
import { getUserIdFromToken } from '../../utils/auth';

interface Order {
  quantity: number;
  status: string;
  menuItemName: string;
  orderId: number;
  totalAmount: string;
  userName: string;
  orderDate: string;
  orderStatus: string;
}

const OrderHistory: React.FC = () => {
  
  const [orderHistory, setOrderHistory] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const userId = getUserIdFromToken();

  const fetchOrderHistory = async () => {
    try {
      const response = await axios.get(`${APIURL}/order-item/order-history/${userId}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setOrderHistory(response.data.data);
    } catch (error) {
      console.error('Error fetching order history:', error);
    }
  };

  useEffect(() => {
    fetchOrderHistory();
  }, []);

  const openModal = (order: Order) => {
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  return (
    <div className="container mx-auto px-4 md:px-6 py-8">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Order History</h1>
      </div>
      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-white w-full ">
          <thead>
            <tr className="w-full bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Menu Name</th>
              <th className="py-3 px-6 text-left">Quantity</th>
              <th className="py-3 px-6 text-left">Username</th>
              <th className="py-3 px-6 text-left">Price</th>
              <th className="py-3 px-6 text-left">Order Status</th>
              <th className="py-3 px-6 text-left">Payment Status</th>
              <th className="py-3 px-6 text-left">Order Date</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {orderHistory.length > 0 ? (
              orderHistory.map((order) => (
                <tr key={order.orderId} className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer" onClick={() => openModal(order)}>
                  <td className="py-3 px-6 text-left">{order.menuItemName}</td>
                  <td className="py-3 px-6 text-left">{order.quantity}</td>
                  <td className="py-3 px-6 text-left">{order.userName}</td>
                  <td className="py-3 px-6 text-left">${order.totalAmount}</td>
                  <td className="py-3 px-6 text-left">{order.orderStatus}</td>
                  <td className="py-3 px-6 text-left">{order.status}</td>
                  <td className="py-3 px-6 text-left">{new Date(order.orderDate).toLocaleDateString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="py-3 px-6 text-center" colSpan={7}>No orders found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {selectedOrder && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">Order Details</h2>
            <p><strong>Menu Name:</strong> {selectedOrder.menuItemName}</p>
            <p><strong>Quantity:</strong> {selectedOrder.quantity}</p>
            <p><strong>Username:</strong> {selectedOrder.userName}</p>
            <p><strong>Price:</strong> ${selectedOrder.totalAmount}</p>
            <p><strong>Order Status:</strong> {selectedOrder.orderStatus}</p>
            <p><strong>Payment Status:</strong> {selectedOrder.status}</p>
            <p><strong>Order Date:</strong> {new Date(selectedOrder.orderDate).toLocaleDateString()}</p>
            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
