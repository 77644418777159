import { motion } from "framer-motion";
import { MdOutlineFastfood } from "react-icons/md";
import { FoodCategory } from "../../../types";

const Button = ({
  category,
  filter,
  setFilter,
}: {
  category: FoodCategory;
  filter: string;
  setFilter: (filter: string) => void;
}) => {
  const isActive = category.urlParam === filter;
  return (
    <motion.div
      onClick={() => setFilter(category.urlParam)}
      whileTap={{ scale: 1.1 }}
      className={`group w-24 min-w-[7rem] h-28 cursor-pointer rounded-lg drop-shadow-xl flex flex-col gap-3 items-center justify-center transition duration-150 ease-out ${
        isActive ? "bg-cartNumBg" : "bg-btnOverlay"
      }`}
    >
      <div
        className={`w-10 h-10 rounded-full flex items-center justify-center bg-${
          isActive ? "btnOverlay" : "cartNumBg"
        }`}
      >
        <span
          className={`text-lg text-${
            isActive ? "textColor" : "btnOverlay"
          } group-hover:text-${
            isActive ? "btnOverlay" : "textColor"
          }`}
        >
          {category.icon || <MdOutlineFastfood />}
        </span>
      </div>
      <p
        className={`text-base text-${
          isActive ? "white" : "textColor"
        } group-hover:text-${isActive ? "textColor" : "white"}`}
      >
        {category.name}
      </p>
    </motion.div>
  );
};

export default Button;