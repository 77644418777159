import React from 'react';
import dineIn from '../../img/dinein.jpg';
import takeaway from '../../img/takeaway.jpg';
import deliveryFood from '../../img/46379.jpg';
import { Link } from 'react-router-dom';

const services = [
  {
    title: 'Dine-In & Takeaway',
    description: 'In our exquisitely designed Precinct, take pleasure in a warm and inviting dining experience. Take your favourite takeaway meals home with you and savour them in luxury.',
    image: dineIn,
  },
  {
    link:'https://www.ubereats.com/nz/store/flavour-fusion/n5AMxJzYRQSfmNBjIxWi0w?ps=1',
    title: 'Uber Eats Delivery',
    description: 'Get your meals delivered right to your doorstep with our fast and reliable delivery service.',
    image: deliveryFood,
  },
  {
    link:'https://www.doordash.com/store/flavour-fusion-christchurch-25654411/',
    title: 'Door Dash Delivery',
    description: 'Get your meals delivered right to your doorstep with our fast and reliable delivery service.',
    image: deliveryFood,
  },
  {
    link:'https://www.delivereasy.co.nz/flavour-fusion-christchurch-delivery',
    title: 'Deliver Easy Delivery',
    description: 'Get your meals delivered right to your doorstep with our fast and reliable delivery service.',
    image: deliveryFood,
  }
];

const ServicePage = () => {
  return (
    <div className="container mx-auto px-4 py-8 bg-gray-900">
      <h1 className="text-4xl font-extrabold text-center mb-6 text-white">Our Services</h1>
      <p className="text-lg text-center mb-8 text-gray-300">
        At Flavour Fusion, we're passionate about serving delicious meals that cater to all your cravings. Our menu features a variety of mouth-watering burgers, crispy fries, and delightful combo meals, perfect for dining in, taking to go, or making your special event unforgettable. Join us and experience the fusion of flavor at its finest!
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {services.map((service, index) => (
          <div
            key={index}
            className="service-card bg-gray-800 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105"
          >
            <Link to={service.link || '/'}>
              <img
                src={service.image}
                alt={service.title}
                className="w-full h-auto object-cover mb-4"
              />
              <div className="p-8 h-auto">
                <h2 className="text-3xl font-semibold mb-4 text-white">{service.title}</h2>
                <p className="text-gray-400">{service.description}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicePage;
